const routesJA = {
  Login: 'ログイン',
  Register: 'アカウント作成',
  ResetPassword: 'パスワードリセット',
  CheckEmail: 'メールを確認してください',
  Action: 'アカウントアクション',
  UserDashboard: 'ダッシュボード',
  UserSettings: '設定',
}

export default routesJA
