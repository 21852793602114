const firebaseConfig = {
  apiKey: 'AIzaSyCOHcxSDUu6G-EfI___yi3T3b2WHO-Zsw8',
  authDomain: 'skeet-framework.firebaseapp.com',
  projectId: 'skeet-framework',
  storageBucket: 'skeet-framework.appspot.com',
  messagingSenderId: '213528932539',
  appId: '1:213528932539:web:1eeaa1fa801646e66ab99e',
  measurementId: 'G-X4CYRQSCT4',
}

export default firebaseConfig
